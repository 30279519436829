import React from "react";
import styles from "./Spotless.module.css";

export const Spotless = () => {
  return (
    <div className={styles.main}>
      <div className={styles.heading}>
        Keep your place spick-and-span spotless!
      </div>
      <div>
        <ol type="1">
          <li className={styles.list}>
            <p>
              <span className={styles.head}>
                Your Cleaning Pros LLC Cleaning Service,
              </span>{" "}
              in all aspects of our business, we strive to earn and maintain the
              trust that our customers give us when they choose Your Cleaning
              Pros LLC cleaning services as their cleaning provider. Thank you
              for considering using our organization. Your Health, Our
              Consignment .... Your Environment Our Core Business.
            </p>
          </li>
          <li className={styles.list}>
            <p>
              <span className={styles.head}>
                Your Cleaning Pros LLC Professional Cleaning Services,
              </span>{" "}
              a commercial cleaning company, was founded in 2018 and all our
              employees are fully insured, and background checks are available
              at request. Expert knowledge is required! Proper planning of
              required tasks, the development of workplace procedures and
              detailed work schedules are the starting point for operations.
              This ensures that all contractual and service objectives are
              incorporated and planned. Employees are introduced to them as part
              of site-specific training and are then assessed against them for
              qualification and ongoing performance monitoring. We find that
              detailed and specific instructions leave little room for confusion
              we believe that employee satisfaction is vital for achieving
              excellence in our service and customer satisfaction!
            </p>
          </li>
          <li className={styles.list}>
            <p>
              <span className={styles.head}>
                Your Cleaning Pros LLC Cleaning
              </span>{" "}
              Services Cleaning is a science of its own, so through continuous
              training, we do our best to ensure that all members of our team
              are competent cleaning experts. Have professional know-how in
              terms of profitable implementation; How many cleaners are needed
              to perform the function, what specifications, what methods, how
              much equipment, materials and so on! Which labor-saving systems
              can be used and the most economical combination of ingredients?
            </p>
          </li>
          <li className={styles.list}>
            <p>
              <span className={styles.head}>Great Opportunity Cleaning</span>{" "}
              Services Cleaning is a science of its own, so through continuous
              training, we do our best to ensure that all members of our team
              are competent contract cleaning experts. Have professional
              know-how in terms of profitable implementation; How many cleaners
              are needed to perform the function, what specifications, what
              methods, how much equipment, materials and so on! Which
              labor-saving systems can be used and the most economical
              combination of ingredients?
            </p>
          </li>
          <li className={styles.list}>
            <p>
              <span className={styles.head}>Your Cleaning Pros LLC</span>{" "}
              Services. Our Service Philosophy ... the demand for our service
              exists because we can offer the customer a means to achieve
              cleaning / cleaning goals consistently, in the most economical
              way, without the complications of everyday life, so that he/she
              can focus on their main activity. Cleaning is our main business,
              achieving these goals is our priority. The recognition of our
              customers for the success of our implementation was and will
              continue to be our ticket to growth and survival in a highly
              competitive environment.
            </p>
          </li>
          <li className={styles.list}>
            <p>
              Why <span className={styles.head}>Your Cleaning Pros LLC?</span>{" "}
              The face of our business - Well-trained, well-presented,
              competent, and qualified people - experienced, skilled, capable,
              reliable, responsible, and equipped to act ... Because Quality is
              Necessary. Our people are our greatest asset.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};
