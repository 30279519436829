import React from "react";
import About from "../Home/About";
import Qoute from "../Home/Qoute";
import Action from "../Home/Action";

const Abou = () => {
  return (
    <>
      <div
        class="container-fluid bg-primary py-5 bg-hero"
        style={{marginBottom: "90px"}}
      >
        <div class="container py-5">
          <div class="row justify-content-start">
            <div class="col-lg-8 text-center text-lg-start">
              <h1 class="display-1 text-dark">About Us</h1>
              <div class="pt-2">
                <a
                  href=""
                  class="btn btn-secondary rounded-pill py-2 px-4 mx-2"
                >
                  Home
                </a>
                <a
                  href=""
                  class="btn btn-secondary rounded-pill py-2 px-4 mx-2"
                >
                  About Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <About />
      <Qoute />
      {/* <Action /> */}
    </>
  );
};

export default Abou;
