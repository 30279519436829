import React, { useState } from "react";
import styles from "./Heading.module.css";
import { Modal } from "antd";
import Estimate from "../Estimate/Estimate";

const Heading = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <div className={styles.main}>
      {/* <div className={styles.logo}>
        <img src="/logo.jpg" alt="logo" className={styles.logo_img} />
      </div> */}
      <div className={styles.heading}>
        COMMERCIAL JANITORIAL <br /> CLEANING SERVICE
      </div>
      <div className={styles.btn}>
        <button className={styles.button} onClick={handleClick}>
          FREE ESTIMATE
        </button>
      </div>
      <div className={styles.description}>
        <p>
          We are an expert Commercial Cleaning company in the Medical, Dental,
          Bank, and Office Janitorial cleaning service in Lancaster County Area.
          Each office space is unique. Whether you require daily office cleaning
          or a weekly or monthly service, we'll customize a cleaning schedule
          specifically geared for your business. From reception areas to
          restrooms, we have the resources and expertise to get the job done.
          Free quotes available upon request.
        </p>
      </div>
      <Modal centered open={open} footer={null} onCancel={() => setOpen(false)}>
        <Estimate />
      </Modal>
    </div>
  );
};

export default Heading;
