import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Button, message, Space } from "antd";

const Qoute = () => {
  const form = useRef();

  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Email Sent!",
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "An error occoured!",
    });
  };

  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "This is a warning message",
    });
  };

  const sendEmail = (e) => {
    // console.log("ji")
    e.preventDefault();

    emailjs
      .sendForm("service_l3q80zl", "template_juojcl6", e.target, {
        subject: "General Inquiry",
        publicKey: "qJIBdGNJQz2j-fJ8h",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          success();
          form.current.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
          error();
        }
      );
  };

  return (
    <div
      class="container-fluid bg-primary bg-quote py-5"
      style={{margin: "90px 0",}}
    >
      {contextHolder}

      <div class="container py-5">
        <div class="row g-0 justify-content-start">
          <div class="col-lg-6" >
            <div class="bg-white text-center p-5">
              <h1 class="mb-4" >Get an Estimate</h1>
              <form ref={form} onSubmit={sendEmail}>
                <div class="row g-3">
                  <div class="col-12 col-sm-6">
                    <input
                      type="text"
                      class="form-control bg-light border-0"
                      placeholder="Your Name"
                      style={{height: "55px"}}
                      name="from_name"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <input
                      type="email"
                      class="form-control bg-light border-0"
                      placeholder="Your Email"
                      name="from_email"
                      style={{height: "55px"}}
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <input
                      type="text"
                      class="form-control bg-light border-0"
                      placeholder="Company Name"
                      name="company"
                      style={{height: "55px"}}
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <input
                      type="text"
                      class="form-control bg-light border-0"
                      placeholder="Phone"
                      style={{height: "55px"}}
                      name="phone"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <input
                      type="text"
                      class="form-control bg-light border-0"
                      placeholder="Address"
                      name="address"
                      style={{height: "55px"}}
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <input
                      type="text"
                      class="form-control bg-light border-0"
                      placeholder="Type of Cleaning"
                      style={{height: "55px"}}
                      name="type"
                    />
                  </div>
                  <div class="col-12">
                    <textarea
                      class="form-control bg-light border-0 py-3"
                      rows="2"
                      name="message"
                      placeholder="Details of cleaning & How soon do you need it?"
                    ></textarea>
                  </div>
                  <div class="col-12" >
                    <button class="btn btn-primary w-100 py-3" type="submit" style={{color:"white"}}>
                    Free Estimate
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Qoute;
