import React from "react";
import "./View.css";
import Nav from "../../components/Nav/Nav";
import Foot from "../../components/Foot/Foot";

const Retail = () => {
  return (
    <>
      <Nav active={"Service"} />

      <div className="service-view">
        <img
          src={
            "https://images.unsplash.com/photo-1563453392212-326f5e854473?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          }
          alt="Service"
          className="service-image"
        />
        <div className="service-info">
          <h2 className="service-title">{"Retail Store Cleaning Services"}</h2>
        </div>
      </div>
      <div className="content">
        <h1>The Clean Your Customers Deserve</h1>
        <p className="service-description">
          Your customers expect and deserve a clean, attractive shopping
          experience every time they walk in the door. An inviting environment
          encourages shoppers to stay longer and return more often.
        </p>
        <p>
          The benefits of professional commercial cleaning services for retail
          stores also extend beyond appearances. A cleaned and disinfected
          retail store tends to have healthier employees, less absenteeism, and
          higher morale.
        </p>
        <p>
          Our retail store cleaning services will allow you to make the best use
          of your company’s resources. Without having to worry about cleaning
          duties, your staff can focus its attention on more important business.
        </p>
        <div className="service-content">
          <h1>Industry Leading Professional Cleaning Services</h1>
          <p>
            The majority of the products we use in retail cleaning are
            eco-friendly, including our cleaning and floor chemicals. We also
            use color-coded micro-fiber cloths that are washable and reusable.
            These products capture dust and dirt particles rather than pushing
            them into the air. We use Hepa Vacuums with filtration technology
            that improves air quality by capturing 99.6 percent of particles 0.3
            microns or greater.
          </p>
          <br />
          <h1>Our Retail Cleaning Services</h1>
          <p>
            Imperial Cleaning is determined to create clean, healthy and
            pleasant retail environments. We provide many different types of
            cleaning services for retail stores, including:
          </p>
          <ul>
            <li style={{listStyle:"circle"}}>White glove janitorial services</li>
            <li style={{listStyle:"circle"}}>Window cleaning</li>
            <li style={{listStyle:"circle"}}>Floor care services</li>
            <li style={{listStyle:"circle"}}>Carpet care</li>
            <li style={{listStyle:"circle"}}>Day or night porter services</li>
            <li style={{listStyle:"circle"}}>Pre- and post-construction clean-ups</li>
          </ul>
          <p>
            Our service schedules are flexible to meet your company’s needs.
            Cleaning can be performed before, during or after normal operating
            hours.
          </p>
          <br />
          <h1>The Your Cleaning Pros Difference</h1>
          <p>
            We work hard to create a clean, safe and healthy environment for
            everyone who enters your store. This Your Cleaning Pros pledge
            (Because Quality is Necessary) is evident in every job we do.
          </p>
          <p>
            We train all our staff members in the proper retail cleaning
            methods, techniques, and products to safeguard your merchandise and
            assets. Our cleaning team members understand the importance of
            attention to detail, quality work, and creating first impressions.
          </p>
          <p>
            All of our professional staff members are under the close
            supervision to ensure that we consistently deliver exceptional
            services.
          </p>
          <p>
            Your Cleaning Pros Cleaning is an industry leader with more than 10
            years of experience in professional commercial cleaning. We provide
            customized services to retail businesses of all types and sizes.
          </p>
          <br />
        </div>
      </div>
      <Foot />
    </>
  );
};

export default Retail;
