import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonial.css";
import { Avatar } from "@material-ui/core";
// import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";

const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      {/* <ArrowBackIos style={{ color: "gray", fontSize: "45px" }} /> */}
      <FaLessThan style={{ color: "gray", fontSize: "35px" }} />
    </div>
  );
};
const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FaGreaterThan style={{ color: "gray", fontSize: "35px" }} />
      {/* <ArrowForwardIos style={{ color: "gray", fontSize: "45px" }} /> */}
    </div>
  );
};
const Testimonial = () => {
  return (
    <div
      className="testimonial"
      style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
    >
      <div style={{ width: "50%", textAlign: "center" }}>
        <h1 style={{ marginBottom: 20 }}>TESTIMONIALS</h1>
        <Slider prevArrow={<PreviousBtn />} nextArrow={<NextBtn />} dots>
          <Card
            name="Denise H. in Ephrata, PA"
            review="Mike and Dianna are a hardworking team! They consistently provide a quality service and present themselves extremely well! Friendly, honest, dependable, and timely are just a few of their great qualities! I hired them to clean my bed and breakfast and onsite restaurant, and I am thoroughly pleased with their professionalism and quality of work! HIGHLY RECOMMEND!"
            profession="Clean Building Interior (Janitorial Service) - For Business"
            img="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
          />
          <Card
            name="Karen M. in New Holland, PA"
            review=" They do an excellent job at a very fair price. We are overjoyed with their quality."
            profession="Clean Building Interior (Janitorial Service) - For Business"
            img="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
          />
          <Card
            name="Waldy G."
            review="They do an excellent job with everything they do! They are very organized. Their prices were reasonable."
            profession="Clean Building Interior (Janitorial Service) - For Business"
            img="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
          />
        </Slider>
      </div>
    </div>
  );
};

const Card = ({ name, review, profession, img }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        color: "gray",
      }}
    >
      <Avatar
        imgProps={{ style: { borderRadius: "50%" } }}
        src={img}
        style={{
          width: 120,
          height: 120,
          border: "1px solid lightgray",
          padding: 7,
          marginBottom: 20,
        }}
      />
      <p>{review}</p>
      <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: "green" }}>{name}</span> ,
        {profession}
      </p>
    </div>
  );
};

export default Testimonial;
