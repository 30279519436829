import React from "react";
import Nav from "../../components/Nav/Nav";
import Foot from "../../components/Foot/Foot";
import Gallery from "../../components/Gallery/Gallery";
import Job from "../Job/Job";
import "./Work.css";

const Work = () => {
  const handleClick = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSfioWwDxor5g0TVBdMqkVcBif2Q4UQMCVmXQQR5VV5NEp43TA/viewform?usp=sf_link",
      "_blank"
    );
  };
  return (
    <>
      <Nav active={"Work"} />
      <div
        class="container-fluid bg-primary py-5 bg-hero"
        style={{ marginBottom: "50px" }}
      >
        <div class="container py-5">
          <div class="row justify-content-start">
            <div class="col-lg-8 text-center text-lg-start">
              <h1 class="display-1 text-dark">Careers</h1>
              <div class="pt-2">
                <a
                  href=""
                  class="btn btn-secondary rounded-pill py-2 px-4 mx-2"
                >
                  Home
                </a>
                <a
                  href=""
                  class="btn btn-secondary rounded-pill py-2 px-4 mx-2"
                >
                  Careers
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Gallery /> */}
      {/* <Job/> */}
      <div className="job">
        <div className="des">
          <h1>Job Openings</h1>
          <h2>
            Below is a list of our current job openings at Your Cleaning Pros
            LLC
          </h2>
          <p>
            Our team is looking for a dependable and experienced Commercial
            Cleaner. You will provide custodial services to various commercial
            properties, including but not limited to sweeping, vacuuming, and
            mopping floors; dusting; and emptying trash bins. You should be
            comfortable cleaning bathrooms as well as other public spaces. Our
            ideal applicant is available to clean properties on days and
            evenings depending on your agreed schedule and has experience using
            various types of cleaning supplies and tools.{" "}
          </p>
        </div>
        <div className="duties">
          <h2>Commercial Cleaner Duties and Responsibilities</h2>
          <ul>
            <li className="_li">Clean bathrooms</li>
            <li className="_li">Clean public areas</li>
            <li className="_li">Vacuum and mop floors</li>
            <li className="_li">
              Empty trash cans and take trash to designated outside trash
              dumpsters
            </li>
            <li className="_li">Dust and wipe surfaces</li>
            <li className="_li">Maintain cleaning supplies and equipment </li>
          </ul>
        </div>
        <div className="req">
          <h2>Commercial Cleaner Requirements and Qualifications</h2>
          <ul>
            <li className="_li">
              Prior cleaning experience strongly preferred. Will train if needed{" "}
            </li>
            <li className="_li">
              Knowledge of cleaning equipment and supplies preferred. Will train
              if needed.
            </li>
            <li className="_li">Ability to lift up to 30 lbs.</li>
            <li className="_li">Valid drivers license and reliable transportation</li>
          </ul>
        </div>
        <div className="note">
          <p>
            All cleaners drive their own personal vehicles. Your Cleaning Pros
            pays $.50/mile plus paid time in between jobs.
          </p>
        </div>
        <div className="table">
          <table class="styled-table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Days of the Week</th>
                <th>Locations</th>
                <th>Time's</th>
                <th>Hours</th>
                <th>Total Hrs Including Drive Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cleaner</td>
                <td>Monday</td>
                <td>Ephrata</td>
                <td>5pm-7pm</td>
                <td>2</td>
                <td rowspan="2">4.5</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Lancaster (Fruitville Pike)</td>
                <td>7:30pm-9:30pm</td>
                <td>2</td>
              </tr>

              <tr>
                <td></td>
                <td>Tuesday</td>
                <td>Ephrata</td>
                <td>5pm-6pm</td>
                <td>1</td>
                <td rowspan="2">2.5</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Greenfield</td>
                <td>6:30pm-7:30pm</td>
                <td>1</td>
              </tr>

              <tr>
                <td></td>
                <td>Wednesday</td>
                <td>Lancaster (Fruitville Pike)</td>
                <td>5pm-7pm</td>
                <td>2</td>
                <td rowspan="2">3.5</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Lancaster City</td>
                <td>7:30pm-8:30pm</td>
                <td>1</td>
              </tr>

              <tr>
                <td></td>
                <td>Thursday</td>
                <td>Ephrata</td>
                <td>5pm-7pm</td>
                <td>2</td>
                <td rowspan="2">3.25</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Ephrata</td>
                <td>7:15pm-8:15pm</td>
                <td>1</td>
              </tr>

              <tr class="active-row">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>13.75</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="bt">
          <button
            className="btn btn-secondary rounded-pill py-3 px-4 mx-2"
            onClick={handleClick}
          >
            Apply Now
          </button>
        </div>
        <p>Attach resume or send to YourCleaningpros2019@gmail.com</p>
      </div>
      <Foot />
    </>
  );
};

export default Work;
