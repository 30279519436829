import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home/Home";
import Services from "./pages/Service/Service";
import Contact from "./pages/Contact/Contact";
import About from "./pages/About/About";
import Work from "./pages/Work/Work";
import View from "./pages/View/View";
import Job from "./pages/Job/Job";
import ScrollToTop from "./ScrollToTop";
import Dental from "./pages/View/Dental";
import Office from "./pages/View/Office";
import Daycare from "./pages/View/Daycare";
import School from "./pages/View/School";
import Retail from "./pages/View/Retail";
import Bank from "./pages/View/Bank";
import Janitorial from "./pages/View/Janitorial";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/jobs" element={<Work />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/about" element={<About />}></Route>
        {/* <Route path="/service-details" element={<View />}></Route> */}

        <Route path="/service-details/dental" element={<Dental />}></Route>
        <Route path="/service-details/office" element={<Office />}></Route>
        <Route path="/service-details/daycare" element={<Daycare />}></Route>
        <Route path="/service-details/school" element={<School />}></Route>
        <Route path="/service-details/retail" element={<Retail />}></Route>
        <Route path="/service-details/bank" element={<Bank />}></Route>
        <Route path="/service-details/janitorial" element={<Janitorial />}></Route>

      </Routes>
      <ScrollToTop />
    </BrowserRouter>
  );
}

export default App;
