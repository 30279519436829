import React from "react";
import styles from "./Info.module.css";

export const Info = () => {
  return (
    <div className={styles.main}>
      <p className={styles.para}>
        Determine Rates - Our prices are governed by square foot size, number of bathrooms, number of levels and the amount of cleaning required. Rates may vary depending on room layout and extras. While each address is unique and will be cleaned according to your needs.
      </p>
      <p className={styles.para}>
      At Your Cleaning Pros LLC we specialize in many different types of cleaning, including commercial janitorial services. Our staff is trained to handle all cleaning jobs. We are the team to call!
      </p>
      <p className={styles.para}>
      We are dedicated to ensuring our customers are happy and the job is done the right way the first time.  It is our goal to get every job completed in a timely manner with the utmost professionalism.  Our attention to detail has evolved over time. Our cleaners have taken the time to learn what needs to be done to make your space appear as clean as possible when it is finished. We work with many different types of properties, so we know what to expect with every situation. 
      </p>
      <p className={styles.para}>
      Our team often does regular cleanings, janitorial services on properties such as office, dental, medical, retail, banking, and school/daycare centers.  Our team takes an interest in the properties before we start cleaning, by finding out all there is to know about the job.  Because we do this it allows us to ensure we have all the resources necessary to complete the cleaning the right way every time. 
      </p>
      <p className={styles.para}>
      At Your Cleaning Pros LLC, we always take the time needed to do the job right and we never rush through a cleaning.  Our customers are very happy with our services, and we always send reliable references to validate our credibility.  We value each and every one of our clients and we will do all it takes to ensure satisfaction. 
      </p>
      <p className={styles.para}>
        Make now to get a{" "}
        <span className={styles.imp}>
          free estimate for your commercial cleaning
        </span>{" "}
        needs!
      </p>
    </div>
  );
};
