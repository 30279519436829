import React, { useEffect, useState } from "react";
import styles from "./Nav.module.css";
import { Outlet, Link } from "react-router-dom";

const Nav = ({ active }) => {
  const [isActive, setIsActive] = useState(false);

  const [home, setHome] = useState(false);
  const [service, setService] = useState(false);
  const [contact, setContact] = useState(false);
  const [about, setAbout] = useState(false);
  const [work, setWork] = useState(false);

  useEffect(() => {
    if (active === "Home") {
      setHome(true);
    } else if (active === "Service") {
      setService(true);
    } else if (active === "Contact") {
      setContact(true);
    } else if (active === "Work") {
      setWork(true);
    } else {
      setAbout(true);
    }
  }, [active]);

  const toggleNav = () => {
    setIsActive(!isActive);
  };

  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 50 ? setSticky(true) : setSticky(false);
    });
  }, []);

  return (
    <header className={`${sticky ? styles.color : ""}`}>
      <div className={styles.logo}>
        <Link to="/">
          <img src="https://urcleaningpros.com/images/logo.png" alt="logo" style={{ height: "90px" }} />
        </Link>
      </div>
      <div className={styles.hamburger} onClick={toggleNav}>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
      </div>
      <nav className={`${styles.nav} ${isActive ? styles.active : ""}`}>
        <ul>
          <li>
            <Link to="/" className={home ? styles.active : null}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/services" className={service ? styles.active : null}>
              Services
            </Link>
          </li>
          <li>
            <Link to="/jobs" className={work ? styles.active : null}>
              Careers
            </Link>
          </li>
          <li>
            <Link to="/contact" className={contact ? styles.active : null}>
              Contact Us
            </Link>
          </li>
          <li>
            <Link to="/about" className={about ? styles.active : null}>
              About Us
            </Link>
          </li>
        </ul>
      </nav>
      <Outlet />
    </header>
  );
};

export default Nav;
