import React from "react";
import Hero from "../../components/Hero/Hero";
import Nav from "../../components/Nav/Nav";
import Start from "../../components/Home/Start";
import About from "../../components/Home/About";
import Services from "../../components/Home/Services";
import Qoute from "../../components/Home/Qoute";
import Testimonial from "../../components/Testimonial/Testimonial";
import Action from "../../components/Home/Action";
import Foot from "../../components/Foot/Foot";

export const Home = () => {
  return (
    <>
      <Nav active={"Home"} />
      <Hero />
      {/* <Start /> */}
      <About />
      <Services />
      <Qoute />
      {/* <Members /> */}
      <Testimonial/>
      {/* <Action /> */}
      <Foot />
    </>
  );
};
