import React from "react";
import { Outlet, Link } from "react-router-dom";

const Services = () => {
  return (
    <div class="container-fluid py-5">
      <div class="container py-5">
        <div class="text-center mx-auto mb-5" style={{ maxWidth: "500px" }}>
          <h1 class="display-5">Professional Commercial Cleaning Services.</h1>
          <hr class="w-25 mx-auto" style={{ opacity: "1", color: "#073F6E" }} />
        </div>
        <div class="row gy-4 gx-3">
          <div
            class="col-lg-4 col-md-6 pt-5"
            
          >
            <div class="service-item d-flex flex-column align-items-center justify-content-center text-center p-5 pt-0" style={{ height:'265px' }}>
              <div class="service-icon p-3">
                <div>
                  <i
                    class="fa fa-2x fa-paint-brush"
                    style={{ color: "white" }}
                  ></i>
                </div>
              </div>
              <h3 class="mt-5" style={{ color: "white" }}>
                Bank Cleaning Services
              </h3>
              <Link
                to="service-details/bank"
                class="btn shadow-none"
                href=""
                style={{ color: "white" }}
              >
                View Detail<i class="bi bi-arrow-right ms-1"></i>
              </Link>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pt-5">
            <div class="service-item d-flex flex-column align-items-center justify-content-center text-center p-5 pt-0"  style={{ height:'265px' }}>
              <div class="service-icon p-3">
                <div>
                  <i
                    class="fa fa-2x fa-paint-roller"
                    style={{ color: "white" }}
                  ></i>
                </div>
              </div>
              <h3 class="mt-5" style={{ color: "white" }}>
                Office Cleaning Service
              </h3>
              <Link
                to="service-details/office"
                class="btn shadow-none"
                href=""
                style={{ color: "white" }}
              >
                View Detail<i class="bi bi-arrow-right ms-1"></i>
              </Link>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pt-5">
            <div class="service-item d-flex flex-column align-items-center justify-content-center text-center p-5 pt-0"  style={{ height:'265px' }}>
              <div class="service-icon p-3">
                <div>
                  <i class="fa fa-2x fa-brush" style={{ color: "white" }}></i>
                </div>
              </div>
              <h3 class="mt-5" style={{ color: "white" }}>
                Daycare Cleaning Service
              </h3>
              <Link
                to="service-details/daycare"
                class="btn shadow-none "
                href=""
                style={{ color: "white" }}
              >
                View Detail<i class="bi bi-arrow-right ms-1"></i>
              </Link>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pt-5">
            <div class="service-item d-flex flex-column align-items-center justify-content-center text-center p-5 pt-0"  style={{ height:'265px' }}>
              <div class="service-icon p-3">
                <div>
                  <i class="fa fa-2x fa-eraser" style={{ color: "white" }}></i>
                </div>
              </div>
              <h3 class="mt-5" style={{ color: "white" }}>
                Janitorial Cleaning Services
              </h3>
              <Link
                to="service-details/janitorial"
                class="btn shadow-none"
                href=""
                style={{ color: "white" }}
              >
                View Detail<i class="bi bi-arrow-right ms-1"></i>
              </Link>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pt-5">
            <div class="service-item d-flex flex-column align-items-center justify-content-center text-center p-5 pt-0"  style={{ height:'265px' }}>
              <div class="service-icon p-3">
                <div>
                  <i
                    class="fa fa-2x fa-spray-can"
                    style={{ color: "white" }}
                  ></i>
                </div>
              </div>
              <h3 class="mt-5" style={{ color: "white" }}>
                Retail Store Cleaning Services
              </h3>
              <Link
                to="service-details/retail"
                class="btn shadow-none"
                href=""
                style={{ color: "white" }}
              >
                View Detail<i class="bi bi-arrow-right ms-1"></i>
              </Link>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pt-5">
            <div class="service-item d-flex flex-column align-items-center justify-content-center text-center p-5 pt-0"  style={{ height:'265px' }}>
              <div class="service-icon p-3">
                <div>
                  <i class="fa fa-2x fa-city" style={{ color: "white" }}></i>
                </div>
              </div>
              <h3 class="mt-5" style={{ color: "white" }}>
                School and Institutional Cleaning Service
              </h3>
              <Link
                to="service-details/school"
                class="btn shadow-none"
                href=""
                style={{ color: "white" }}
              >
                View Detail<i class="bi bi-arrow-right ms-1"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
