import React from "react";
import "./View.css";
import Nav from "../../components/Nav/Nav";
import Foot from "../../components/Foot/Foot";

const Daycare = () => {
  return (
    <>
      <Nav active={"Service"} />

      <div className="service-view">
        <img
          src={
            "https://images.unsplash.com/photo-1563453392212-326f5e854473?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          }
          alt="Service"
          className="service-image"
        />
        <div className="service-info">
          <h2 className="service-title">{"Daycare Cleaning Service"}</h2>
        </div>
      </div>
      <div className="content">
        <h1>
          Cleaning Services for Childcare, Daycare, and Preschool Facilities
        </h1>
        <p className="service-description">
          Our childcare cleaning services are tailored to the needs of your
          daycare or preschool facility. We understand the nuances of cleaning
          preschools, daycares, and other childcare facilities. We know the
          importance of performing comprehensive background checks on our
          janitorial service providers to ensure students’ safety, and all our
          daycare cleaning crews are bonded and insured.
        </p>
        <div className="service-content">
          <h1>Are Your Childcare Cleaning Services Cutting It?</h1>
          <p>
            When it comes to cleaning and disinfecting daycares, preschools, and
            childcare facilities, thorough and up-to-date cleaning and
            disinfecting procedures are more critical than ever. We are offering
            additional tools and techniques like electrostatic disinfectant
            sprayers to enable our cleaning crews to sanitize and disinfect the
            high-touch surfaces and high-traffic areas throughout your childcare
            facility. These tools can help reduce the spread of germs and
            illnesses such as the seasonal flu and Covid-19 in densely
            trafficked areas in your preschool, daycare, or childcare facility.
          </p>
          <br />
          <h1>
            Keeping a childcare or daycare facility clean and sanitary is
            critical to providing a safe and healthy environment for children.
          </h1>
          <p>
            In daycares, preschools, and primary schools, where germs run
            rampant, a regular cleaning schedule minimizes the spread of
            illness-causing germs. Some of the most important areas on our
            crews' daycare cleaning service offerings include:
          </p>
          <br />
          <h4>Food preparation areas</h4>
          <p>
            Food preparation areas, including kitchen surfaces, should be
            cleaned and sanitized regularly to prevent the spread of germs and
            bacteria that can cause foodborne illnesses.
          </p>

          <h4>Sleeping areas</h4>
          <p>
            If the facility provides napping or sleeping areas, they should be
            regularly cleaned and disinfected.
          </p>

          <h4>Restrooms</h4>
          <p>
            Restrooms are high-traffic areas that require frequent cleaning to
            prevent the spread of germs and bacteria. This includes cleaning and
            disinfecting toilets, sinks, and changing tables.
          </p>

          <h4>Floors and surfaces</h4>
          <p>
            Floors and surfaces should be regularly cleaned and disinfected to
            prevent the spread of germs and bacteria that can cause illnesses.
          </p>

          <h4>Staff areas</h4>
          <p>
            Staff areas, including break rooms and offices, should also be
            regularly cleaned and disinfected to prevent the spread of germs and
            bacteria.
          </p>
        </div>
      </div>
      <Foot />
    </>
  );
};

export default Daycare;
