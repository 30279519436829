import React, { useRef } from "react";
import Action from "../Home/Action";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { Button, message, Space } from "antd";
const Contac = () => {

  const form = useRef();

  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Email Sent!",
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "An error occoured!",
    });
  };

  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "This is a warning message",
    });
  };

  const sendEmail = (e) => {
    // console.log("ji")
    e.preventDefault();

    emailjs
      .sendForm("service_l3q80zl", "template_juojcl6", e.target, {
        subject: "General Inquiry",
        publicKey: "qJIBdGNJQz2j-fJ8h",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          success();
          form.current.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
          error();
        }
      );
  };

  return (
    <>
      {contextHolder}

      <div
        class="container-fluid bg-primary py-5 bg-hero"
      >
        <div class="container py-5">
          <div class="row justify-content-start">
            <div class="col-lg-8 text-center text-lg-start">
              <h1 class="display-1 text-dark">Contact Us</h1>
              <div class="pt-2">
                <a
                  href=""
                  class="btn btn-secondary rounded-pill py-2 px-4 mx-2"
                >
                  Home
                </a>
                
                <a
                  href=""
                  class="btn btn-secondary rounded-pill py-2 px-4 mx-2"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid py-5">
        <div class="container py-5">
          <div class="text-center mx-auto mb-5" style={{ maxWidth: "500px" }}>
            <h1 class="display-5">Please Feel Free To Contact Us</h1>
            <hr class="w-25 mx-auto" style={{ opacity: "1", color:"#073F6E" }} />
          </div>
          <div class="row g-3 mb-5">
            <div class="col-lg-4 col-md-6 pt-5">
              <div class="contact-item d-flex flex-column align-items-center justify-content-center text-center pb-5">
                <div class="contact-icon p-3">
                  <div style={{color:"white"}}>
                    <i class="fa fa-2x fa-map-marker-alt"></i>
                  </div>
                </div>
                <h4 class="mt-5" style={{color:"white"}}>294 Oakridge Dr. Mountville, Pa 17554</h4>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 pt-5">
              <div class="contact-item d-flex flex-column align-items-center justify-content-center text-center pb-5">
                <div class="contact-icon p-3">
                  <div style={{color:"white"}}>
                    <i class="fa fa-2x fa-phone"></i>
                  </div>
                </div>
                <h4 class="mt-5"  style={{color:"white"}}>717-725-0056</h4>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 pt-5">
              <div class="contact-item d-flex flex-column align-items-center justify-content-center text-center pb-5">
                <div class="contact-icon p-3">
                  <div style={{color:"white"}}>
                    <i class="fa fa-2x fa-envelope-open"></i>
                  </div>
                </div>
                <h4 class="mt-5"  style={{color:"white"}}>Yourcleaningpros2019@gmail.com</h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12" style={{ height: "500px"}}>
              <div class="position-relative h-100" >
                <iframe
                  class="position-relative w-100 h-100"
                  src="https://www.google.com/maps/d/embed?mid=12vxMczxOE1iHn4D8gSEjnAmFomc&hl=en&ehbc=2E312F"
                  frameborder="0"
                  style={{border:"0"}}
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </div>
            </div>
          </div>
          <div
            class="row justify-content-center position-relative"
            style={{ marginTop: "-200px", zIndex: "1"}}
          >
            <div class="col-lg-8">
              <div class="p-5 m-5 mb-0" style={{backgroundColor:"#073F6E", borderRadius:"20px"}}>
                <form ref={form} onSubmit={sendEmail}>
                  <div class="row g-3">
                    <div class="col-12 col-sm-6">
                      <input
                        type="text"
                        class="form-control bg-light border-0"
                        placeholder="Your Name"
                        style={{ height: "55px" }}
                        name="from_name"
                      />
                    </div>
                    <div class="col-12 col-sm-6">
                      <input
                        type="email"
                        class="form-control bg-light border-0"
                        placeholder="Your Email"
                        name="from_email"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div class="col-12">
                      <input
                        type="text"
                        class="form-control bg-light border-0"
                        placeholder="Subject"
                        style={{ height: "55px" }}
                        name="subject"
                      />
                    </div>
                    <div class="col-12">
                      <textarea
                        class="form-control bg-light border-0"
                        rows="5"
                        placeholder="Message"
                        name="message"
                      ></textarea>
                    </div>
                    <div class="col-12">
                      <button
                        class="btn btn-secondary w-100 py-3"
                        type="submit"
                        style={{backgroundColor:"white", color:"#073F6E"}}
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Action /> */}
    </>
  );
};

export default Contac;
