import React from "react";
import styles from "./ServiceCard.module.css";
import { Outlet, Link } from "react-router-dom";

const ServiceCard = ({ data }) => {
  const { heading, description, image } = data;
  const t = `/service-details/${data?.service}`
  return (
    <div className={styles.main}>
      <div className={styles.image}>
      <Link to={t}>
        <img
          src={image}
          alt="img"
          className={styles.img}
        />
        </Link>
      </div>
      <div className={styles.heading}>{heading}</div>
      <div className={styles.description}>
        {description}
      </div>
    </div>
  );
};

export default ServiceCard;
