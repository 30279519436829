import React, { useEffect } from "react";
import "./Hero.css";

const Hero = () => {
  useEffect(() => {
    const nextDom = document.getElementById("next");
    const prevDom = document.getElementById("prev");
    const carouselDom = document.querySelector(".carousel");
    const SliderDom = carouselDom.querySelector(".carousel .list");
    const thumbnailBorderDom = document.querySelector(".carousel .thumbnail");

    thumbnailBorderDom.appendChild(
      thumbnailBorderDom.querySelectorAll(".item")[0]
    );

    let timeRunning = 3000;
    let timeAutoNext = 7000;
    let runTimeOut;
    let runNextAuto = setTimeout(() => {
      nextDom.click();
    }, timeAutoNext);

    nextDom.onclick = function () {
      showSlider("next");
    };

    prevDom.onclick = function () {
      showSlider("prev");
    };

    function showSlider(type) {
      const SliderItemsDom = SliderDom.querySelectorAll(
        ".carousel .list .item"
      );
      const thumbnailItemsDom = document.querySelectorAll(
        ".carousel .thumbnail .item"
      );

      if (type === "next") {
        SliderDom.appendChild(SliderItemsDom[0]);
        thumbnailBorderDom.appendChild(thumbnailItemsDom[0]);
        carouselDom.classList.add("next");
      } else {
        SliderDom.prepend(SliderItemsDom[SliderItemsDom.length - 1]);
        thumbnailBorderDom.prepend(
          thumbnailItemsDom[thumbnailItemsDom.length - 1]
        );
        carouselDom.classList.add("prev");
      }
      clearTimeout(runTimeOut);
      runTimeOut = setTimeout(() => {
        carouselDom.classList.remove("next");
        carouselDom.classList.remove("prev");
      }, timeRunning);

      clearTimeout(runNextAuto);
      runNextAuto = setTimeout(() => {
        nextDom.click();
      }, timeAutoNext);
    }

    return () => {
      nextDom.onclick = null;
      prevDom.onclick = null;
    };
  }, []);

  return (
    <>
      <div className="carousel">
        <div className="list">
          <div className="item">
            <img src="images/1.jpg" alt="img" />
            <div className="content">
              {/* <div className="author">CLEANER</div> */}
              <div className="title">Bank Cleaning </div>
              <div className="topic">Services</div>
              <div className="des">
                Our teams offer a reliable & detail-oriented cleaning to keep
                your Bank space looking great. We will help your bank make a
                great first impression.
              </div>
              {/* <div className="buttons">
                <button>SEE MORE</button>
                <button>CONTACT</button>
              </div> */}
            </div>
          </div>
          <div className="item">
            <img src="images/2.jpg" alt="img" />
            <div className="content">
              {/* <div className="author">CLEANER</div> */}
              <div className="title">Office Cleaning </div>
              <div className="topic">Services</div>
              <div className="des">
                We provide office cleaning services in Lancaster, as well as
                other areas, including Lititz, Manheim, Ephrata, Quarryville,
                East York ...
              </div>
              {/* <div className="buttons">
                <button>SEE MORE</button>
                <button>CONTACT</button>
              </div> */}
            </div>
          </div>
          <div className="item">
            <img src="images/3.jpg" alt="img" />
            <div className="content">
              {/* <div className="author">CLEANER</div> */}
              <div className="title">Janitorial Cleaning </div>
              <div className="topic">Services</div>
              <div className="des">
                Our goal is to provide complete customer satisfaction, keeping
                your property and employees happy with a thoroughly clean, safe,
                and healthy environment.
              </div>
              {/* <div className="buttons">
                <button>SEE MORE</button>
                <button>CONTACT</button>
              </div> */}
            </div>
          </div>
          <div className="item">
            <img src="images/4.jpg" alt="img" />
            <div className="content">
              {/* <div className="author">CLEANER</div> */}
              <div className="title">Dental/Medical Office Cleaning</div>
              <div className="topic">Service</div>
              <div className="des">
                Our Dental and Medical Office cleaners will take care to make
                sure that your office is clean, completely sanitized, and looks
                great for your patients.
              </div>
              {/* <div className="buttons">
                <button>SEE MORE</button>
                <button>CONTACT</button>
              </div> */}
            </div>
          </div>
        </div>
        <div className="thumbnail">
          <div className="item">
            <img src="images/1.jpg" alt="img" />
            <div className="content">
              <div className="title"></div>
              <div className="description"></div>
            </div>
          </div>
          <div className="item">
            <img src="images/2.jpg" alt="img" />
            <div className="content">
              <div className="title"></div>
              <div className="description"></div>
            </div>
          </div>
          <div className="item">
            <img src="images/3.jpg" alt="img" />
            <div className="content">
              <div className="title"></div>
              <div className="description"></div>
            </div>
          </div>
          <div className="item">
            <img src="images/4.jpg" alt="img" />
            <div className="content">
              <div className="title"></div>
              <div className="description"></div>
            </div>
          </div>
        </div>
        <div className="arrows">
          <button id="prev">{"<"}</button>
          <button id="next">{">"}</button>
        </div>
      </div>
    </>
  );
};

export default Hero;
