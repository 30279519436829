import React from "react";
import Nav from "../../components/Nav/Nav";
import Abou from "../../components/Abou/Abou";
import Foot from "../../components/Foot/Foot";

const About = () => {
  return (
    <>
      <Nav active={"About"}/>
      <Abou/>
      <Foot/>
    </>
  );
};

export default About;
