import React from "react";
import styles from "./Services.module.css";
import ServiceCard from "../ServiceCard/ServiceCard";
import { img_1, img_2, img_3, img_4, img_5, img_6, img_7 } from "../../images";

const Services = () => {
  return (
    <div className={styles.main}>
      <div className={styles.heading}>Commercial cleaning services</div>
      <div className={styles.cards}>
        <ServiceCard
          data={{
            image: img_1,
            heading: "Dental / Medical Office cleaners",
            description:
              "Our Dental and Medical Office cleaners will take care to make sure that your office is clean, completely sanitized, and looks great for your patients.",
              service: "dental"
          }}
        />
        <ServiceCard
          data={{
            image: img_2,
            heading: "Office Cleaning Service",
            description:
              "We provide office cleaning services in Lancaster, as well as other areas, including Lititz, Manheim, Ephrata, Quarryville, East York ...",
              service: "office"
            }}
        />
        <ServiceCard
          data={{
            image: img_3,
            heading: "Daycare Cleaning Service",
            description:
              "We provide commercial cleaning services to daycare’s, preschools, and childcare centers. We will help keep your daycare or educational facility clean, sanitized and inviting.",
              service: "daycare"
            }}
        />
        <ServiceCard
          data={{
            image: img_4,
            heading: "School and Institutional Cleaning Service",
            description:
              "When you operate a school, you aren’t just responsible for your students’ education — you’re also responsible for their safety.  The cleanliness of the school is essential.",
              service: "school"
            }}
        />
        <ServiceCard
          data={{
            image: img_5,
            heading: "Retail Store Cleaning Services",
            description:
              "We specialize in department stores and small businesses. We’ll customize a program that works for you and each of your locations and we’ll do it within your budget.",
              service: "retail"
            }}
        />
        <ServiceCard
          data={{
            image: img_6,
            heading: "Bank Cleaning Services",
            description:
              "Our teams offer a reliable & detail-oriented cleaning to keep your Bank space looking great. We will help your bank make a great first impression.",
              service: "bank"
            }}
        />
        <ServiceCard
          data={{
            image: img_7,
            heading: "Janitorial Cleaning Services",
            description:
              "Our goal is to provide complete customer satisfaction, keeping your property and employees happy with a thoroughly clean, safe, and healthy environment.",
              service: "janitorial"
            }}
        />
      </div>
    </div>
  );
};

export default Services;
