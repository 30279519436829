import React, { useRef } from "react";
import styles from "./Estimate.module.css";
import { Input, Select } from "antd";
import emailjs from "@emailjs/browser";
import { Button, message, Space } from "antd";
const { TextArea } = Input;

const Estimate = () => {
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const form = useRef();

  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Email Sent!",
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "An error occoured!",
    });
  };

  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "This is a warning message",
    });
  };

  const sendEmail = (e) => {
    // console.log("ji")
    e.preventDefault();

    emailjs
      .sendForm("service_l3q80zl", "template_juojcl6", e.target, {
        subject: "General Inquiry",
        publicKey: "qJIBdGNJQz2j-fJ8h",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          success();
          form.current.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
          error();
        }
      );
  };


  return (
    <div className={styles.container}>
      {contextHolder}

      <div className={styles.heading}>FREE ESTIMATE COMMERCIAL CLEANING</div>
      <div className={styles.form}>
      <form ref={form} onSubmit={sendEmail}>
        <Input className={styles.input} placeholder="Name" name="from_name"/>
        <Input className={styles.input} placeholder="Company Name" name="company"/>
        <Input className={styles.input} placeholder="Email" name="from_email"/>
        <Input className={styles.input} placeholder="Phone" name="phone"/>
        <Input className={styles.input} placeholder="Address" name="address"/>
          <Select
            className={styles.select}
            name="type"
            placeholder="Type of cleaning"
            onChange={handleChange}
            options={[
              { value: "Dental / Medical Office cleaning", label: "Dental / Medical Office cleaning" },
              { value: "Office Cleaning", label: "Office Cleaning" },
              { value: "Daycare Cleaning", label: "Daycare Cleaning" },
              { value: "School and Institutional Cleaning", label: "School and Institutional Cleaning" },
              { value: "Retail Store Cleaning", label: "Retail Store Cleaning" },
              { value: "Bank Cleaning", label: "Bank Cleaning" },
              { value: "Janitorial Cleaning", label: "Janitorial Cleaning" },
            ]}
          />
        <TextArea
          className={styles.input}
          rows={4}
          name="message"
          placeholder="Details of cleaning & How soon do you need it?"
        />
      <div className={styles.button}>
        <button className={styles.btn} type="submit">SEND</button>
      </div>
      </form>
      </div>
    </div>
  );
};

export default Estimate;
