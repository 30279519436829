import React from "react";
import "./View.css";
import Nav from "../../components/Nav/Nav";
import Foot from "../../components/Foot/Foot";

const Bank = () => {
  return (
    <>
      <Nav active={"Service"} />

      <div className="service-view">
        <img
          src={
            "https://images.unsplash.com/photo-1563453392212-326f5e854473?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          }
          alt="Service"
          className="service-image"
        />
        <div className="service-info">
          <h2 className="service-title">{"Bank Cleaning Services"}</h2>
        </div>
      </div>
      <div className="content">
        <p className="service-description">
          Have you ever walked into a financial institution or bank and felt
          like it wasn’t clean and kept up? Probably not. Most professional
          workplaces like banks are kept clean and orderly. It’s important to
          have a pleasant atmosphere for both customers and staff alike, and the
          condition of a financial institution gives clients a strong impression
          of its reputation. Next, we’ll discuss commercial cleaning services
          for banks and financial institutions.
        </p>
        <div className="service-content">
          <h1>
            Commercial Cleaning Services for Banks and Financial Institutions
          </h1>
          <p>
            Many commercial cleaning crews are trained to clean offices and
            other businesses that are sensitive in nature. Cleaning a financial
            institution can be considered a sensitive industry. As such,
            commercial cleaning teams should be bonded and insured as well as
            specifically trained to be conscious and careful of discreet,
            secure, and controlled business locations.
          </p>
          <p>
            A good commercial cleaning team will handle common cleaning tasks
            for a bank or financial institution just like it would for many
            other types of businesses. Those regular cleaning tasks include:
          </p>
          <ul>
            <li style={{listStyle:"circle"}}>Break room and restroom cleaning and sanitizing</li>
            <li style={{listStyle:"circle"}}>Floor sweeping, vacuuming, and mopping</li>
            <li style={{listStyle:"circle"}}>Dusting </li>
            <li style={{listStyle:"circle"}}>Offices, meeting rooms, </li>
            <li style={{listStyle:"circle"}}>teller line, common area cleaning</li>
            <li style={{listStyle:"circle"}}>Wall cleaning touch-ups</li>
            <li style={{listStyle:"circle"}}>Debris pickup</li>
            <li style={{listStyle:"circle"}}>Trash removal</li>
            <li style={{listStyle:"circle"}}>Supply restocking</li>
          </ul>
          <p>
            Deeper commercial cleaning for banks is a must either quarterly or
            monthly depending on the extent of foot traffic in a particular
            location. Deeper cleaning during viral outbreaks can be helpful in
            preventing the spread of germs as well.
          </p>
          <ul>
            <li style={{listStyle:"circle"}}>Carpet cleaning</li>
            <li style={{listStyle:"circle"}}>Window cleaning</li>
            <li style={{listStyle:"circle"}}>Deep clean tile flooring</li>
            <li style={{listStyle:"circle"}}>Electrostatic & fogging disinfecting</li>
          </ul>
          <h1>Efficient Ways to Clean Banks and Financial Institutions</h1>
          <p>
            Much of a bank’s cleaning can be done when the bank is closed.
            After-hours cleaning will allow a more thorough cleaning job from
            the cleaning team, too. Cleaning a bank or financial institution
            during a quiet period will provide a cleaning team access to all
            areas necessary without having to take time to navigate around
            crowds or working staff.
          </p>
          <p>
            If you hire a team to clean your bank, it can be accomplished in a
            shorter amount of time. Often, wet areas like kitchens and restrooms
            are cleaned first, then working through the office areas and out to
            the lobby. Dusting, polishing glass or mirrors, and cleaning floors
            will likely finish out your efficient cleaning rotation.
          </p>
          <p>
            Banks can be high-traffic environments, with many customers and
            employees coming and going during the day. Routine cleaning will be
            necessary, but daily maintenance like light touch-ups and wipe-downs
            of commonly-touched surfaces could be needed. Each bank location
            will be different depending on size and traffic volume
          </p>
          <p>
            We all know how important cleaning, sanitizing, and disinfecting can
            be for a workplace. A bank is no different. It’s vital to provide a
            clean, safe environment to help keep everyone healthy.
          </p>
          <br />
          <h1>When You’re Ready for a Consistently-Clean Bank</h1>
          <p>
            The easiest way to keep your bank clean on a regular basis is to
            hire a professional team to clean it. Let us do the cleaning work
            for you.
          </p>
          <p>
            Our professional cleaning staff is bonded, insured, and maintains
            OSHA compliance. We are happy to clean your bank
          </p>
        </div>
      </div>
      <Foot />
    </>
  );
};

export default Bank;
