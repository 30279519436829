import React from "react";
import { Outlet, Link } from "react-router-dom";

const Foot = () => {
  return (
    <>
      <div class="container-fluid bg-dark bg-footer text-light py-5">
        <div class="container py-5">
          <div class="row g-5">
            <div class="col-lg-4 col-md-6">
              <h4 class="" style={{color: "white"}}>Get In Touch</h4>
              <hr class="w-25 mb-4" style={{ opacity: "1", color:"#073F6E" }} />
              <p class="mb-4">
              Hours of Operation:  Monday – Sunday 8am – 9pm
              </p>
              <p class="mb-2">
                <i class="fa fa-map-marker-alt me-3" style={{color: "#073F6E"}}></i>294 Oakridge Dr. Mountville, Pa 17554
              </p>
              <p class="mb-2">
                <i class="fa fa-envelope  me-3" style={{color: "#073F6E"}}></i>Yourcleaningpros2019@gmail.com
              </p>
              <p class="mb-0">
                <i class="fa fa-phone-alt me-3" style={{color: "#073F6E"}}></i>717-725-0056
              </p>
            </div>
            <div class="col-lg-4 col-md-6">
              <h4 class="" style={{color: "white"}}>Our Services</h4>
              <hr class="w-25  mb-4" style={{ opacity: "1",color: "#073F6E" }} />
              <div class="d-flex flex-column justify-content-start">
                <Link class="text-light mb-2" to="/services">
                  <i class="fa fa-angle-right me-2" style={{color: "#073F6E"}}></i>Dental / Medical Office cleaners
                </Link>
                <Link class="text-light mb-2"  to="/services">
                  <i class="fa fa-angle-right me-2"style={{color: "#073F6E"}}></i>Office Cleaning Service
                </Link>
                <Link class="text-light mb-2"  to="/services">
                  <i class="fa fa-angle-right me-2" style={{color: "#073F6E"}}></i>Daycare Cleaning Service
                </Link>
               
                <Link class="text-light mb-2"  to="/services">
                  <i class="fa fa-angle-right me-2" style={{color: "#073F6E"}}></i>Retail Store Cleaning Services
                </Link>
                <Link class="text-light"  to="/services">
                  <i class="fa fa-angle-right me-2" style={{color: "#073F6E"}}></i>Bank Cleaning Services
                </Link>
                <Link class="text-light"  to="/services">
                  <i class="fa fa-angle-right me-2" style={{color: "#073F6E"}}></i>Janitorial Cleaning Services
                </Link>
                <Link class="text-light mb-2"  to="/services">
                  <i class="fa fa-angle-right me-2" style={{color: "#073F6E"}}></i>School and Institutional Cleaning Service
                </Link>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <h4 class="" style={{color: "white"}}>Quick Links</h4>
              <hr class="w-25  mb-4" style={{ opacity: "1",color: "#073F6E" }} />
              <div class="d-flex flex-column justify-content-start">
                <Link class="text-light mb-2"  to="/">
                  <i class="fa fa-angle-right me-2" style={{color: "#073F6E"}}></i>Home
                </Link>
                <Link class="text-light mb-2"  to="/about">
                  <i class="fa fa-angle-right me-2" style={{color: "#073F6E"}}></i>About Us
                </Link>
                <Link class="text-light mb-2"  to="/services">
                  <i class="fa fa-angle-right me-2" style={{color: "#073F6E"}}></i>Our Services
                </Link>
            
                <Link class="text-light"  to="/contact">
                  <i class="fa fa-angle-right me-2" style={{color: "#073F6E"}}></i>Contact Us
                </Link>
              </div>
            </div>
            {/* <div class="col-lg-3 col-md-6">
              <h4 class="" style={{color: "#073F6E"}}>Newsletter</h4>
              <hr class="w-25 text-secondary mb-4" style={{ opacity: "1",color: "#073F6E" }} />
              <form action="">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control p-3 border-0"
                    placeholder="Your Email"
                  />
                  <button class="btn" style={{backgroundColor: "#073F6E", color:"white"}}>Sign Up</button>
                </div>
              </form>
              <h6 class=" mt-4 mb-3" style={{color: "#073F6E"}}>Follow Us</h6>
              <div class="d-flex">
                <a
                  class="btn btn-lg btn-primary btn-lg-square rounded-circle me-2"
                  href="#"
                  style={{backgroundColor: "#073F6E", border:"none"}}
                >
                  <i class="fab fa-twitter"></i>
                </a>
                <a
                  class="btn btn-lg btn-primary btn-lg-square rounded-circle me-2"
                  href="#"
                  style={{backgroundColor: "#073F6E", border:"none"}}

                >
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a
                  class="btn btn-lg btn-primary btn-lg-square rounded-circle me-2"
                  href="#"
                  style={{backgroundColor: "#073F6E", border:"none"}}

                >
                  <i class="fab fa-linkedin-in"></i>
                </a>
                <a
                  class="btn btn-lg btn-primary btn-lg-square rounded-circle"
                  href="#"
                  style={{backgroundColor: "#073F6E", border:"none"}}

                >
                  <i class="fab fa-instagram"></i>
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div class="container-fluid text-dark py-4" style={{backgroundColor:"#18185E"}}>
        <div class="container">
          <div class="row g-0">
            <div class="col-md-6 text-center text-md-start">
              <p class="mb-md-0" style={{color:"white"}}>
                Copyright &copy;{" "}
                <a class="fw-bold" href="#" style={{color:"white"}}>
                  Your Cleaning Pros
                </a>
                . All Rights Reserved.
              </p>
            </div>
            <div class="col-md-6 text-center text-md-end">
              <p class="mb-0" style={{color:"white"}}>
                Designed by{" "}
                <a class=" fw-bold" href="https://htmlcodex.com">
                  Digiunction
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Foot;
