import React from "react";
import "./Contact.css";
import Nav from "../../components/Nav/Nav";
import Contac from "../../components/Contac/Contac";
import Foot from "../../components/Foot/Foot";

const Contact = () => {
  return (
    <>
      <Nav active={"Contact"}/>
      <Contac/>
      <Foot/>
    </>
  );
};

export default Contact;
